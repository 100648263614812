import axios from "axios";
//import {CSRF_TOKEN} from './csrf.js'

//  const api = axios.create({
//      baseURL : "http://localhost:8000",
//      headers: {
//          'content-type': 'application/json',
//          'X-CSRFTOKEN': CSRF_TOKEN
//      }
//  });

const api = axios.create({
  withCredentials: true,
  // baseURL : "http://127.0.0.1:8000",
  // baseURL : "http://103.106.118.154:1016",
  // baseURL : "https://ttzdev.pythonanywhere.com",
  //   baseURL: "https://myhotelapi.choicepms.com",
  baseURL: "https://demoapi.choicepms.com/",
  headers: { "Content-Type": "application/json" },
  credentials: "include",
});
let refresh = false;

api.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error.response.status === 403 && !refresh) {
      refresh = true;
      //    console.log("Entered interceptors")
      const token = localStorage.getItem("jwt");
      const r_token = localStorage.getItem("r_token");
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const { status, data } = await api.post(
        "/auth/refresh",
        { r_token: r_token },
        {
          withCredentials: true,
        }
      );
      //    console.log("Entered interceptors", status)
      if (status === 200) {
        api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

        return api(error.config);
      }
    }
    refresh = false;
    return error;
  }
);

export default api;

//  export default api;
